.viewer-iframe {
    border: none;
    width: 100%;
    height: 100%;
}

.viewer-toolbar,
.viewer-statusbar,
.viewer-navigation {
    display: none;
}

div,span,h2,h3,h4{
    font-family: 'Montserrat'!important;
  }